import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const AuthorCard = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiAuthor {
        edges {
          node {
            thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: WEBP
                    webpOptions: {quality: 85}
                    quality: 85
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const authorData = data.allStrapiAuthor.edges[0].node; // Isso pega o primeiro autor
  const image = getImage(authorData.thumbnail.localFile); // Convertendo para formato aceito pelo GatsbyImage

  return (
    <div className="w-64 md:w-704 flex flex-col md:flex-row  items-center bg-black bg-opacity-60 p-4  ring-1 ring-orange-500 ring-opacity-40 rounded-2xl mt-6 p-sm">
      {/* Foto do autor */}
      <div className="mr-4 relative -left-2 ">
        <GatsbyImage image={image} alt="Nome do Autor" className="rounded-full w-32 h-32 md:w-64 md:h-64" />
      </div>

      {/* Detalhes do autor */}
      <div className="flex-grow">
        <h2  className="font-bold text-3xl relative -left-2">Djames Suhanko</h2>
        <p className="text-sm text-gray-400 mb-2 relative -left-2">
            Autor do blog "Do bit Ao Byte / Manual do Maker".<br/><br/>
            Viciado em embarcados desde 2006.< br/>
            
            LinuxUser 158.760, desde 1997.
            </p>
        
        {/* Links de redes sociais e e-mail */}
        <div className="flex opacity-70 flex-row space-x-2 right-4 relative -bottom-14  rounded-lg text-sm">
          <div className='m-2'><a href="https://twitter.com/DjamesSuhanko" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            Twitter: @DjamesSuhanko
          </a></div>
          <div className='m-2'><a href="https://github.com/DjamesSuhanko" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
            Github: DjamesSuhanko
          </a></div>
          <div className='m-2'><a href="mailto:dobitaobyte@gmail.com" aria-label="Email">
            Email: dobitaobyte@gmail.com
          </a></div>
        </div>
      </div>
    </div>
  );
}

export default AuthorCard;
