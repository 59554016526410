import React, { useEffect } from "react";
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import bbLogo from "../images/manualdomaker.svg"
import AuthorCard from "../components/AuthorCard";

export default function Article({ data }) {
  const article = data.strapiArticle
  const parse = require("html-react-parser")

  const replaceImage = {
    replace: function (node) {
      if (
        node.type === "tag" &&
        node.name === "img" &&
        node.attribs.src != ""
      ) {
        const image = article.body.medias.filter((image) => {
          return image.src == node.attribs.src;
        });

        const src = getImage(image[0].localFile.childrenImageSharp[0]);

        return <GatsbyImage image={src} alt={node.attribs.alt} />;
      }
    },
  }; 
  
  const thumbnailImage = getImage(article.thumbnail.localFile.childImageSharp);
  const thumbnailUrl   = getSrc(article.thumbnail.localFile.childImageSharp);

  useEffect(() => {
    // Verifica se estamos no ambiente de navegador
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SE4NY559V3');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render


  return (
    <Layout>
      <div className="relative">
        <div className=" centered-content bg-cover bg-fixed ring-2 ring-orange-500 bg-black bg-opacity-60 overflow-hidden rounded-2xl backdrop-blur-sm">
          <div className="mb-8">
            <div className="h-60 bg-gradient-to-b from-black to-transparent to-50%   w-full">
              <div
                className="relative h-40 top-12 bg-black opacity-70 rounded-xl w-80 ring-opacity-30 ring-1 ring-neonGreen"
                style={{
                  backgroundImage: `url(${bbLogo})`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p className="absolute left-2 -top-5 font-sans font-medium z-20 text-[44px] opacity-90">Manual</p>
                            <p className="absolute left-36 top-12 font-sans font-medium z-20 text-[24px]">do</p>
                            <p className="absolute right-2 top-20 font-sans font-medium z-20 text-[44px] opacity-90">Maker</p>
                            <p className="absolute text-orange-500 left-1/2 translate-x-1/2 top-7 font-sans font-medium z-20 text-[96px] opacity-90 mt-7 ml-16 ">.</p>
                            <p className="absolute text-orange-500 left-2/3 ml-12 mt-6 top-28 font-sans font-medium z-20 text-[28px] opacity-90">com</p>
          
                {/* <CarrousselPro /> */}
              </div>
            </div>
            <div>
              {/* As imagens devem ter 1200 x 720 */}
              <div className="m-4  rounded-tl-3xl rounded-br-4xl overflow-hidden h-96 md:h-96 lg:h-704 shadow-inner relative">
                <h1
                  className="z-20 p-xs text-center text-white w-full bg-orange-400 bg-opacity-50 drop-shadow-2xl absolute top-0 left-0"
                >
                  {article.title}
                </h1>
                <GatsbyImage image={thumbnailImage} alt={article.title} className="min-h-full min-w-full object-cover" />
              </div>
            </div>
            <div className="m-4">
              {parse(article.body.data.childMarkdownRemark.html, replaceImage)}
            </div>
            <div className="relative md:-left-6 md:-bottom-14 lg:bottom-0 lg:left-0 p-sm">
            <AuthorCard />
            </div>
          </div>
        </div>
        
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    strapiArticle(id: { eq: $id }) {
      excerpt
      title
      slug
      date
      body {
        data {
          childMarkdownRemark {
            html
            timeToRead
          }
        }
        medias {
          src
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: WEBP
                webpOptions: { quality: 85 }
                quality: 85
              )
            }
          }
        }
      }
      thumbnail {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: WEBP
              webpOptions: { quality: 85 }
              quality: 85
            )
          }
        }
      }
      category {
        slug
      }
    }
  }
`;

export const Head = ({ data }) => (
  <SEO
    title={data.strapiArticle.title}
    description={data.strapiArticle.excerpt}
    pathname={`article/${data.strapiArticle.slug}`}
    thumbnail={getSrc(data.strapiArticle.thumbnail.localFile.childImageSharp)}
    readingTime={data.strapiArticle.body.data.childMarkdownRemark.timeToRead}
    children={""}
    pageType={"Article"}
    publishedAt={data.strapiArticle.date.split('T')[0]}
  />
);
